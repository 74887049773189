export const IS_DEV = import.meta.env.DEV;
export const IS_STAGING = import.meta.env.MODE === 'staging';
export const IS_PROD = import.meta.env.PROD;

export const IS_E2E = !!(window as any).Cypress;
export const ENV_MODE = import.meta.env.MODE;

export const LAUNCHDARKLY_CONFIG = {
  clientId: import.meta.env.VITE_LAUNCHDARKLY_CLIENT_SIDE_ID || '',
  defaultUserKey: import.meta.env.VITE_LAUNCHDARKLY_USER_KEY || 'user_key',
  defaultUserName: import.meta.env.VITE_LAUNCHDARKLY_USER_NAME || 'user_name',
};

export const MILLISECONDS_IN_A_SECOND = 1000;
export const SECONDS_IN_A_MINUTE = 60;
export const NO_OF_MINUTES_TO_STALE = 5;

export const QUERY_DEBOUNCE_TIME = 350;
