import { ReactElement } from 'react';

import { ErrorBoundary } from '@datadog/rum-react-integration';

import type { WithChildren } from '@global-types';
declare type FallbackRenderer = (error: Error) => React.ReactNode;

type ErrorBoundaryProps = WithChildren<{
  fallback: ReactElement | FallbackRenderer;
}>;

export const AppErrorBoundary = ({
  children,
  fallback,
}: ErrorBoundaryProps) => {
  const props = { fallback };
  return <ErrorBoundary {...props}>{children}</ErrorBoundary>;
};
