import { initTracker } from '@shared/features/event-tracking';

import { TopLevelProviders } from './components';
import { initDataDog } from './services';

initTracker();
initDataDog();

export const App = (props: { children: React.ReactNode }) => {
  return <TopLevelProviders>{props.children}</TopLevelProviders>;
};
export default App;
