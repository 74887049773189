import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

export const TooltipPrimitiveContent = styled(TooltipPrimitive.Content)`
  color: #eee;
  background-color: black;
  padding: 8px 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3rem;
  transform-origin: var(--radix-tooltip-content-transform-origin);
  animation: ${fadeIn} 0.2s ease-out;
  max-width: 320px;
`;
