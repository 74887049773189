import { useLocation } from 'react-router-dom';

import { useTrackEventEffect } from '../useTrackEventEffect';

type UseTrackRoutesParameters = {
  enabled: boolean;
};

export const useTrackRoutes = ({ enabled }: UseTrackRoutesParameters) => {
  const { pathname, search, hash } = useLocation();

  useTrackEventEffect(
    'Page Viewed',
    {
      enabled,
      route: `${pathname}${search}${hash}`,
    },
    [pathname, search, hash]
  );
};
