import { radii, spacing, font } from './primitives';

export type OmniTheme = typeof theme;

export const theme = {
  color: {
    text: {
      default: 'var(--color-text-default)',
      inverse: 'var(--color-text-inverse)',
      error: 'var(--color-text-error)',
      disabled: 'var(--color-text-disabled)',
      hint: 'var(--color-text-hint)',
      positive: 'var(--color-text-positive)',
      negative: 'var(--color-text-negative)',
      warning: 'var(--color-text-warning)',
      accent: 'var(--color-text-accent)',
      link: {
        default: 'var(--color-text-link-default)',
        hover: 'var(--color-text-link-hover)',
      },
    },
    background: {
      primary: {
        default: 'var(--color-background-primary-default)',
        alternative: 'var(--color-background-primary-alternative)',
      },
      secondary: 'var(--color-background-secondary)',
      tertiary: 'var(--color-background-tertiary)',
      inverse: 'var(--color-background-inverse)',
      overlay: 'var(--color-background-overlay)',
      positive: {
        default: 'var(--color-background-positive-default)',
        light: 'var(--color-background-positive-light)',
      },
      negative: {
        default: 'var(--color-background-negative-default)',
        light: 'var(--color-background-negative-light)',
      },
      warning: {
        default: 'var(--color-background-warning-default)',
        light: 'var(--color-background-warning-light)',
      },
    },
    button: {
      primary: {
        active: 'var(--color-button-primary-active)',
        hover: 'var(--color-button-primary-hover)',
        selected: 'var(--color-button-primary-selected)',
        disabled: 'var(--color-button-primary-disabled)',
      },
      secondary: {
        active: 'var(--color-button-secondary-active)',
        hover: 'var(--color-button-secondary-hover)',
        selected: 'var(--color-button-secondary-selected)',
        disabled: 'var(--color-button-secondary-disabled)',
      },
      tertiary: {
        active: 'var(--color-button-tertiary-active)',
        hover: 'var(--color-button-tertiary-hover)',
        selected: 'var(--color-button-tertiary-selected)',
        disabled: 'var(--color-button-tertiary-disabled)',
      },
    },
    tag: {
      positive: {
        background: 'var(--color-tag-positive-background)',
        color: 'var(--color-tag-positive-color)',
      },
      negative: {
        background: 'var(--color-tag-negative-background)',
        color: 'var(--color-tag-negative-color)',
      },
      warning: {
        background: 'var(--color-tag-warning-background)',
        color: 'var(--color-tag-warning-color)',
      },
      accent: {
        background: 'var(--color-tag-accent-background)',
        color: 'var(--color-tag-accent-color)',
      },
      neutral: {
        background: 'var(--color-tag-neutral-background)',
        color: 'var(--color-tag-neutral-color)',
      },
    },
  },
  radii,
  spacing,
  font,
} as const;
