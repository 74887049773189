import { Outlet } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { IS_E2E } from '@shared/constants';

export const PrivateRoute = () => {
  const ProtectedOutlet = withAuthenticationRequired(Outlet);

  const Route = IS_E2E ? Outlet : ProtectedOutlet;

  return <Route />;
};
