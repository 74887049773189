import { useAtlasAuth } from '@shared/features/authentication';
import {
  useTrackerEffect,
  useTrackRoutes,
} from '@shared/features/event-tracking';

import { WithChildren } from '@global-types';

export const TrackPageViewProvider = ({ children }: WithChildren) => {
  const auth = useAtlasAuth();
  useTrackerEffect(
    (tracker) => {
      if (auth.user) {
        tracker.identify(auth.user.sub);
        tracker.people.set_once({
          email: auth.user.email,
        });
      }
    },
    [auth.user]
  );

  useTrackRoutes({ enabled: !!auth.user });

  return <>{children}</>;
};
