import { useNavigate } from 'react-router-dom';
import {
  Auth0Provider as Auth0Provider,
  type AppState,
} from '@auth0/auth0-react';

import type { WithChildren } from '@global-types';

import {
  AUTH0_AUDIENCE,
  AUTH0_BASE_URL,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
} from '../../constants';

export const Auth0LocalProvider = ({ children }: WithChildren) => {
  const navigate = useNavigate();

  const REDIRECT_URI =
    typeof window !== 'undefined' ? window.location.origin : AUTH0_BASE_URL;

  if (!AUTH0_DOMAIN || !AUTH0_CLIENT_ID || !AUTH0_AUDIENCE) {
    throw new Error('Auth0 environment variables are not set');
  }

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || '/');
  };

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: AUTH0_AUDIENCE,
        redirect_uri: REDIRECT_URI,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
