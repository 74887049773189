import { Box } from '@omnipresentgroup/design-system';

import type { WithChildren } from '@global-types';

export const Main = ({ children }: WithChildren) => {
  return (
    <Box data-cy="AppMain" as="main" py="32">
      {children}
    </Box>
  );
};
