import { useState, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { IS_E2E } from '@shared/constants';

import { AUTH0_LOCALSTORAGE } from '../../constants';
import type { AuthenticatedUser } from '../../types';

type AuthState = {
  token: string;
  user: AuthenticatedUser;
};

export const useAtlasAuth = () => {
  const auth0 = useAuth0<AuthenticatedUser>();

  const [authentication, setAuthentication] = useState<AuthState>();

  const IS_E2E_TESTS = IS_E2E && authentication;
  useEffect(() => {
    const storageValue = localStorage.getItem(AUTH0_LOCALSTORAGE);
    if (storageValue !== null) {
      setAuthentication(JSON.parse(storageValue));
    }
  }, []);

  const user = IS_E2E_TESTS ? authentication?.user : auth0.user;
  return {
    user: user,
    isLoading: auth0.isLoading,
    authError: auth0.error,
    logout: auth0.logout,
  };
};
