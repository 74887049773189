import { ErrorPage } from '@shared/pages';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProviders } from '@shared/features/authentication';
import { queryClient } from '@shared/services';
import { FeatureFlagsProvider } from '@shared/features/feature-flag';
import {
  TrackPageViewProvider,
  TrackFindInBrowser,
} from '@shared/features/event-tracking/providers';

import type { WithChildren } from '@global-types';

import { GlobalThemeProvider } from '../../styles';
import { AppErrorBoundary } from '../ErrorBoundary';
import { BaseProviders } from '../BaseProviders';

export const TopLevelProviders = ({ children }: WithChildren) => (
  <QueryClientProvider client={queryClient}>
    <BaseProviders>
      <GlobalThemeProvider>
        <HelmetProvider>
          <AuthProviders>
            <TrackPageViewProvider>
              <TrackFindInBrowser>
                <FeatureFlagsProvider>
                  <AppErrorBoundary
                    fallback={(error) => (
                      <ErrorPage
                        error={error}
                        resetError={null}
                        componentStack={error?.stack}
                      />
                    )}
                  >
                    {children}
                    <ReactQueryDevtools />
                  </AppErrorBoundary>
                </FeatureFlagsProvider>
              </TrackFindInBrowser>
            </TrackPageViewProvider>
          </AuthProviders>
        </HelmetProvider>
      </GlobalThemeProvider>
    </BaseProviders>
  </QueryClientProvider>
);
