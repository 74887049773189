import { useEffect, useState, useCallback, useMemo } from 'react';

import * as LDClient from 'launchdarkly-js-client-sdk';
import type { AuthenticatedUser } from '@shared/features/authentication';
import { LAUNCHDARKLY_CONFIG } from '@shared/constants';

export const useLaunchDarklyClient = (
  authUser: AuthenticatedUser | undefined
) => {
  const [flags, setFlags] = useState({});

  const userContext = useMemo(
    () => ({
      kind: 'user',
      name: `${authUser?.name}` || LAUNCHDARKLY_CONFIG.defaultUserName,
      key: `${authUser?.nickname}` || LAUNCHDARKLY_CONFIG.defaultUserKey,
    }),
    [authUser]
  );

  const initializeClient = useCallback(() => {
    if (Object.keys(flags).length === 0 && userContext?.key) {
      const launchDarklyClient = LDClient.initialize(
        LAUNCHDARKLY_CONFIG.clientId,
        userContext
      );

      launchDarklyClient.waitUntilReady().then(() => {
        const featureFlags = launchDarklyClient.allFlags();
        setFlags(featureFlags);
      });
    }
  }, [userContext, flags]);

  useEffect(() => authUser && initializeClient(), [authUser, initializeClient]);

  return { initializeClient, flags };
};
