import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';
import { IS_DEV } from '@shared/constants';

import { IS_MIXPANEL_ENABLED, MIXPANEL_TOKEN } from '../../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const trackerLogger = <T extends string>(method: T, ...props: any[]) =>
  console.log(`[MIXPANEL] ${method}:`, ...props);

const consoleTracker = {
  track: (event, props) => trackerLogger('track', event, props),
  identify: (uniqueId) => trackerLogger('identify', uniqueId),
  register: (props) => trackerLogger('register', props),
  people: {
    set_once: (props) => trackerLogger('people.set_once', props),
  },
} as OverridedMixpanel;

export const initTracker = () => {
  if (IS_MIXPANEL_ENABLED && MIXPANEL_TOKEN) {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: IS_DEV,
      ip: false,
      // Mixpanel cookies will only be transmitted over https
      secure_cookie: true,
    });
  }
};

export const tracker = IS_MIXPANEL_ENABLED ? mixpanel : consoleTracker;
