import { useEffect, useLayoutEffect, DependencyList, useRef } from 'react';

import { Dict } from 'mixpanel-browser';

import { trackEvent } from '../../utils';

type EventArgs = Dict & {
  enabled?: boolean;
};

export const useTrackEventEffect = (
  event: string,
  args?: EventArgs | null,
  deps: DependencyList = []
) => {
  const { enabled: isEnabled = true, ...dict } = args ?? {};

  const dictRef = useRef<Dict>(dict);

  /**
   * doing this prevents the dict object from re-running the useEffect because of new references on re-renders,
   * while keeping the latest values when it's time to log the event
   */
  useLayoutEffect(() => {
    dictRef.current = dict;
  });

  useEffect(() => {
    if (isEnabled) {
      trackEvent(event, dictRef.current ?? undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, isEnabled, ...deps]);
};
