import { useMemo } from 'react';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import { RedirectRoot } from '@shared/routes/RedirectRoot';
import { IS_E2E } from '@shared/constants';
import { useFeatureFlag } from '@shared/features/feature-flag';
export const InternalUsersRoute = () => {
  const isExternalUser = useFeatureFlag(
    'omniAtlasExternalUsersMode'
  ).isFlagEnabled;
  const Route = useMemo(() => {
    return IS_E2E ? Outlet : withAuthenticationRequired(Outlet);
  }, []);

  return !isExternalUser ? <Route /> : <RedirectRoot />;
};
