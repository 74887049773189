import { Fragment, type ReactElement } from 'react';

import { Typography, Stack } from '@omnipresentgroup/design-system';
import { Layout } from '@shared/components';

import type { WithChildren } from '@global-types';

import * as S from './Subheader.styles';

type SubheaderProps = WithChildren<{
  title?: string;
  distribution?: 'center' | 'end' | 'start';
  fullPage?: boolean;
  filterComponent?: ReactElement;
}>;

export const Subheader = (props: SubheaderProps) => {
  const {
    title,
    distribution = 'center',
    fullPage = false,
    filterComponent,
    children,
  } = props;

  const LayoutWrapper = fullPage ? Fragment : Layout;

  return (
    <S.Wrapper data-cy="AppSubheader">
      <LayoutWrapper>
        <Stack
          align={distribution}
          justify="space-between"
          py="16"
          px={fullPage ? '64' : undefined}
        >
          {title && (
            <Typography color="on-color" as="h5" hideParagraphSpacing size="24">
              {title}
            </Typography>
          )}

          {children}

          {filterComponent}
        </Stack>
      </LayoutWrapper>
    </S.Wrapper>
  );
};
