import { Fragment, type ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { Alert, Stack } from '@omnipresentgroup/design-system';
import { useAtlasAuth } from '@shared/features/authentication';
import {
  Main,
  Layout,
  Subheader,
  Spinner,
  TwoColumnGrid,
} from '@shared/components';

import type { WithChildren } from '@global-types';

type PageProps = WithChildren<{
  title: string;
  hidePageTitleSuffix?: boolean;
  subheader?: ReactElement;
  hideSubheader?: boolean;
  viewMenu?: ReactElement;
  loading?: boolean;
  layout?: 'default' | 'two-column';
  errors?: string;
}>;

export const Page = (props: PageProps) => {
  const { isLoading: auth0Loading } = useAtlasAuth();

  const {
    children,
    title,
    hidePageTitleSuffix = false,
    subheader,
    hideSubheader,
    viewMenu,
    loading: isLoading,
    layout = 'default',
    errors,
  } = props;

  const shouldDisplayLoading = isLoading || auth0Loading;

  const MainWrapper = layout === 'two-column' ? TwoColumnGrid : Main;
  const LayoutWrapper = layout === 'two-column' ? Fragment : Layout;

  return (
    <>
      <Helmet>
        <title>{`${title}${!hidePageTitleSuffix ? `- OmniAtlas` : ''}`}</title>
      </Helmet>

      {!hideSubheader ? subheader || <Subheader /> : null}
      {viewMenu}

      <MainWrapper>
        <LayoutWrapper>
          {errors && (
            <>
              <Alert
                intent="error"
                description={errors}
                title="Error"
                id="error"
                dismissible
              />
              <br />
            </>
          )}
          {shouldDisplayLoading ? (
            <Stack
              justify="vertical"
              align="center"
              css={{ height: 'calc(100vh - 380px)' }}
            >
              <Spinner py="16" />
            </Stack>
          ) : (
            children
          )}
        </LayoutWrapper>
      </MainWrapper>
    </>
  );
};
