import '@omnipresentgroup/design-tokens/dist/css/fonts.css';
import '@omnipresentgroup/design-system/dist/styles.css';

import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';
import { THEME_CSS_VARIABLES } from '@shared/theme/newStyleGuide';
import { type Theme } from '@shared/theme';

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>(({ theme }) => {
  return css`
    ${THEME_CSS_VARIABLES};
    ${normalize};

    html,
    body,
    div,
    ol,
    ul,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0;
    }

    ol,
    ul {
      list-style: none;
    }

    fieldset {
      border: none;
    }

    :root {
      height: 100%;
      overflow-y: scroll;
    }

    body {
      height: auto;
      font-family: ${theme.fonts.body};
      background-color: ${theme.colors.bg.primary};
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale;
    }

    body,
    #root {
      min-height: 100vh;
      position: relative;
    }

    #root {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    a {
      font-family: ${theme.fonts.body};
      font-size: ${theme.sizes[1]};
      font-weight: ${theme.fontWeights.medium};
      color: ${theme.colors.text.primary};
      line-height: ${theme.lineHeights.copy};
      cursor: pointer;
      text-decoration: none;
      user-select: none;

      &:hover {
        text-decoration: underline;
      }
    }
  `;
});
