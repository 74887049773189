import { css } from 'styled-components';

import { lightColors } from '../theme/primitives';

export const THEME_CSS_VARIABLES = css`
  :root,
  body[data-theme='light'] {
    --color-text-default: ${lightColors.brand.black};
    --color-text-inverse: ${lightColors.brand.white};
    --color-text-error: ${lightColors.red.primary};
    --color-text-disabled: ${lightColors.gray[700]};
    --color-text-hint: ${lightColors.gray[500]};
    --color-text-positive: ${lightColors.green.dark};
    --color-text-negative: ${lightColors.red.dark};
    --color-text-warning: ${lightColors.orange.dark};
    --color-text-accent: ${lightColors.blue.dark};
    --color-text-link-default: ${lightColors.brand.primary[400]};
    --color-text-link-hover: ${lightColors.brand.primary[500]};

    --color-background-primary-default: ${lightColors.gray[50]};
    --color-background-primary-alternative: ${lightColors.brand.white};
    --color-background-secondary: ${lightColors.gray[100]};
    --color-background-tertiary: ${lightColors.gray[200]};
    --color-background-inverse: ${lightColors.gray[900]};
    --color-background-overlay: ${lightColors.brand.black}4d;
    --color-background-positive-default: ${lightColors.green.primary};
    --color-background-positive-light: ${lightColors.green.light};
    --color-background-negative-default: ${lightColors.red.primary};
    --color-background-negative-light: ${lightColors.red.light};
    --color-background-warning-default: ${lightColors.orange.primary};
    --color-background-warning-light: ${lightColors.orange.light};

    --color-button-primary-active: ${lightColors.brand.primary[400]};
    --color-button-primary-hover: ${lightColors.brand.primary[500]};
    --color-button-primary-selected: ${lightColors.brand.primary[600]};
    --color-button-primary-disabled: ${lightColors.brand.primary[200]};
    --color-button-secondary-active: ${lightColors.gray[200]};
    --color-button-secondary-hover: ${lightColors.brand.black};
    --color-button-secondary-selected: ${lightColors.gray[900]};
    --color-button-secondary-disabled: ${lightColors.gray[100]};
    --color-button-tertiary-active: none;
    --color-button-tertiary-hover: ${lightColors.gray[100]};
    --color-button-tertiary-selected: ${lightColors.gray[400]};
    --color-button-tertiary-disabled: none;

    --color-tag-positive-background: ${lightColors.green.light};
    --color-tag-positive-color: ${lightColors.green.dark};
    --color-tag-negative-background: ${lightColors.red.light};
    --color-tag-negative-color: ${lightColors.red.dark};
    --color-tag-warning-background: ${lightColors.orange.light};
    --color-tag-warning-color: ${lightColors.orange.dark};
    --color-tag-accent-background: ${lightColors.blue.light};
    --color-tag-accent-color: ${lightColors.blue.dark};
    --color-tag-neutral-background: ${lightColors.gray[100]};
    --color-tag-neutral-color: ${lightColors.brand.black};
  }

  /* body[data-theme='dark'] {
    --color-text-default: ${lightColors.brand.black};
    --color-text-inverse: ${lightColors.brand.white};
    --color-text-error: ${lightColors.red.primary};
    --color-text-disabled: ${lightColors.gray[700]};
    --color-text-hint: ${lightColors.gray[500]};
    --color-text-positive: ${lightColors.green.dark};
    --color-text-negative: ${lightColors.red.dark};
    --color-text-warning: ${lightColors.orange.dark};
    --color-text-accent: ${lightColors.blue.dark};
    --color-text-link-default: ${lightColors.brand.primary[400]};
    --color-text-link-hover: ${lightColors.brand.primary[500]};

    --color-background-primary-default: ${lightColors.gray[50]};
    --color-background-primary-alternative: ${lightColors.brand.white};
    --color-background-secondary: ${lightColors.gray[100]};
    --color-background-tertiary: ${lightColors.gray[200]};
    --color-background-inverse: ${lightColors.gray[900]};
    --color-background-overlay: ${lightColors.brand.black}4d;
    --color-background-positive-default: ${lightColors.green.primary};
    --color-background-positive-light: ${lightColors.green.light};
    --color-background-negative-default: ${lightColors.red.primary};
    --color-background-negative-light: ${lightColors.red.light};
    --color-background-warning-default: ${lightColors.orange.primary};
    --color-background-warning-light: ${lightColors.orange.light};

    --color-button-primary-active: ${lightColors.brand.primary[400]};
    --color-button-primary-hover: ${lightColors.brand.primary[500]};
    --color-button-primary-selected: ${lightColors.brand.primary[600]};
    --color-button-primary-disabled: ${lightColors.brand.primary[200]};
    --color-button-secondary-active: ${lightColors.gray[200]};
    --color-button-secondary-hover: ${lightColors.brand.black};
    --color-button-secondary-selected: ${lightColors.gray[900]};
    --color-button-secondary-disabled: ${lightColors.gray[100]};
    --color-button-tertiary-active: none;
    --color-button-tertiary-hover: ${lightColors.gray[100]};
    --color-button-tertiary-selected: ${lightColors.gray[400]};
    --color-button-tertiary-disabled: none;

    --color-tag-positive-background: ${lightColors.green.light};
    --color-tag-negative-background: ${lightColors.red.light};
    --color-tag-warning-background: ${lightColors.orange.light};
    --color-tag-accent-background: ${lightColors.blue.light};
    --color-tag-neutral-background: ${lightColors.gray[100]};
  } */
`;
