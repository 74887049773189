import { type ComponentProps } from 'react';

import styled, { keyframes } from 'styled-components';
import { OmniMark } from '@shared/assets';
import { Box } from '@omnipresentgroup/design-system';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const RotatingLoader = styled(OmniMark)`
  animation: ${spin} 1s linear infinite;
`;

export interface SpinnerProps extends ComponentProps<typeof Box> {
  title?: string;
}

export const Spinner = ({ title, ...props }: ComponentProps<typeof Box>) => {
  return (
    <Box m="auto" {...props}>
      <RotatingLoader title={title || 'Loading…'} />
    </Box>
  );
};
