import { ThemeProvider } from 'styled-components';
import { theme } from '@shared/theme';

import type { WithChildren } from '@global-types';

import { GlobalStyle } from './GlobalStyle';

export const GlobalThemeProvider = ({ children }: WithChildren) => (
  <ThemeProvider theme={theme}>
    {children}
    <GlobalStyle />
  </ThemeProvider>
);
