import { Box } from '@omnipresentgroup/design-system';

import type { WithChildren } from '@global-types';

export const TwoColumnGrid = ({ children }: WithChildren) => {
  return (
    <Box
      data-cy="AppMain"
      as="main"
      css={{ display: 'grid', gridTemplateColumns: '296px 1fr', flex: 1 }}
    >
      {children}
    </Box>
  );
};
