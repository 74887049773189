import { useContext } from 'react';

import { FeatureFlagsFactory } from '../../contexts/FeatureFlagsFactory';

export const useFeatureFlagsFactory = () => {
  const context = useContext(FeatureFlagsFactory);

  if (!context) {
    throw new Error(
      'useFeatureFlagsFactory must be used within a FeatureFlagsProvider'
    );
  }

  return context;
};
