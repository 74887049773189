import React, { StrictMode, Suspense } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import {
  InternalUsersRoute,
  PrivateRoute,
} from '@shared/features/authentication';
import { RedirectRoot } from '@shared/routes/RedirectRoot';
import { Spinner } from '@shared/components';

import { App } from './app/App';

const Atlas = React.lazy(() => import('app_atlas/app/App'));
const Config = React.lazy(() => import('app_config/app/App'));

const rootNode = document.querySelector('#root');
if (rootNode) {
  const appRoot = createRoot(rootNode);
  appRoot.render(
    <StrictMode>
      <BrowserRouter>
        <App>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              <Route index element={<RedirectRoot />} />
              <Route
                index
                path="territories/*"
                element={
                  <Suspense fallback={<Spinner />}>
                    <Atlas viewPath="territories" />
                  </Suspense>
                }
              />

              <Route
                index
                path="policies/*"
                element={
                  <Suspense fallback={<Spinner />}>
                    <Atlas viewPath="policies" />
                  </Suspense>
                }
              />

              <Route path="lsp-library" element={<InternalUsersRoute />}>
                <Route
                  path="*"
                  index
                  element={
                    <Suspense fallback={<Spinner />}>
                      <Atlas viewPath="lsp-library" />
                    </Suspense>
                  }
                />
              </Route>

              <Route path="calculator" element={<InternalUsersRoute />}>
                <Route
                  path="*"
                  index
                  element={
                    <Suspense fallback={<Spinner />}>
                      <Atlas viewPath="calculator" />
                    </Suspense>
                  }
                />
              </Route>

              <Route path="our-products" element={<InternalUsersRoute />}>
                <Route
                  path="/our-products/*"
                  index
                  element={
                    <Suspense fallback={<Spinner />}>
                      <Atlas viewPath="our-products" />
                    </Suspense>
                  }
                />
              </Route>

              <Route path="config" element={<InternalUsersRoute />}>
                <Route
                  path="*"
                  index
                  element={
                    <Suspense fallback={<Spinner />}>
                      <Config />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            <Route path="*" element={<Atlas viewPath="notFound" />} />
          </Routes>
        </App>
      </BrowserRouter>
    </StrictMode>
  );
}
