import { createContext } from 'react';

import { useAtlasAuth } from '@shared/features/authentication';
import { useLaunchDarklyClient } from '@shared/hooks';

import type { WithChildren } from '@global-types';

import type { FeatureFlag } from '../../types';

export const FeatureFlagsFactory = createContext<FeatureFlag | null>(null);

export const FeatureFlagsProvider = ({ children }: WithChildren) => {
  const auth = useAtlasAuth();
  const { flags } = useLaunchDarklyClient(auth?.user);

  return (
    <FeatureFlagsFactory.Provider value={flags}>
      {children}
    </FeatureFlagsFactory.Provider>
  );
};
