import { convertToRem } from '../../utils';

const size = {
  12: convertToRem(12),
  14: convertToRem(14),
  16: convertToRem(16),
  18: convertToRem(18),
  20: convertToRem(20),
  24: convertToRem(24),
  28: convertToRem(28),
  32: convertToRem(32),
  36: convertToRem(36),
  40: convertToRem(40),
};

const lineHeight = {
  16: convertToRem(16),
  20: convertToRem(20),
  24: convertToRem(24),
  28: convertToRem(28),
  32: convertToRem(32),
  36: convertToRem(36),
  40: convertToRem(40),
  44: convertToRem(44),
  48: convertToRem(48),
};

const weight = {
  regular: 'regular',
  medium: 'medium',
} as const;

const style = {
  heading1: `
    font-size: ${size[40]};
    line-height: ${lineHeight[48]};
    font-weight: ${weight.medium};
  `,
  heading2: `
    font-size: ${size[36]};
    line-height: ${lineHeight[44]};
    font-weight: ${weight.medium};
  `,
  heading3: `
    font-size: ${size[32]};
    line-height: ${lineHeight[40]};
    font-weight: ${weight.medium};
  `,
  heading4: `
    font-size: ${size[28]};
    line-height: ${lineHeight[36]};
    font-weight: ${weight.medium};
  `,
  heading5: `
    font-size: ${size[24]};
    line-height: ${lineHeight[32]};
    font-weight: ${weight.medium};
  `,
  heading6: `
    font-size: ${size[20]};
    line-height: ${lineHeight[28]};
    font-weight: ${weight.medium};
  `,
  paragraph1: `
    font-size: ${size[18]};
    line-height: ${lineHeight[28]};
    font-weight: ${weight.regular};
  `,
  paragraph2: `
    font-size: ${size[16]};
    line-height: ${lineHeight[24]};
    font-weight: ${weight.regular};
  `,
  paragraph3: `
    font-size: ${size[14]};
    line-height: ${lineHeight[20]};
    font-weight: ${weight.regular};
  `,
  paragraph4: `
    font-size: ${size[12]};
    line-height: ${lineHeight[20]};
    font-weight: ${weight.regular};
  `,
  label1: `
    font-size: ${size[18]};
    line-height: ${lineHeight[24]};
    font-weight: ${weight.medium};
  `,
  label2: `
    font-size: ${size[16]};
    line-height: ${lineHeight[20]};
    font-weight: ${weight.medium};
  `,
  label3: `
    font-size: ${size[14]};
    line-height: ${lineHeight[16]};
    font-weight: ${weight.medium};
  `,
  label4: `
    font-size: ${size[12]};
    line-height: ${lineHeight[16]};
    font-weight: ${weight.medium};
  `,
} as const;

export const font = {
  size,
  weight,
  lineHeight,
  style,
} as const;
