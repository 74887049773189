import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { IS_PROD } from '@shared/constants';
export const initDataDog = () => {
  if (import.meta.env.VITE_DATADOG_APPLICATION_ID) {
    const config = {
      applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN || 'fake-token',
      site: 'datadoghq.eu',
      service: 'omniatlas',
      env: import.meta.env.VITE_DATADOG_ENV || 'fake-env',
      version: '0.0.0',
      // https://docs.datadoghq.com/real_user_monitoring/browser/#browser-and-browser-premium-sampling-configuration
      sampleRate: 100, // controls the percentage of overall sessions being tracked
      premiumSampleRate: 100, //applied after the overall sample rate, and controls the percentage of sessions tracked as Browser Premium.
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
      // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum#setup-rum
      allowedTracingOrigins: [
        /https:\/\/.*\.knowledge-api\.dev\.omnipresent\.com/,
        /https:\/\/.*\.calculator-api\.dev\.omnipresent\.com/,
        'https://calculator-api.omnipresent.com',
        'https://calculator-api.dev.omnipresent.com',
        'https://knowledge-api.dev.omnipresent.com',
        'https://knowledge-api.omnipresent.com',
      ],
      trackFrustrations: true,
    };
    datadogRum.init(config as RumInitConfiguration);
    if (IS_PROD) {
      datadogRum.startSessionReplayRecording();
    } else {
      console.info(`Skipping DataDog Session Replay recording.`);
    }
  } else {
    console.info(`Skipping DataDog RUM initialisation.`);
  }
};

export { datadogRum } from '@datadog/browser-rum';
