import { theme as omniTheme } from './newStyleGuide';

const fonts = {
  body: '"Inter", system-ui, sans-serif',
};

const fontSizes = [
  '0.75rem',
  '0.875rem',
  '1rem',
  '1.5rem',
  '2rem',
  '2.5rem',
  '3rem',
];

const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
};

const lineHeights = {
  solid: 1,
  title: 1.25,
  copy: 1.5,
};

const letterSpacings = {
  default: 'normal',
};

const borderWidths = [1, 2];

const space = [0, 4, 8, 12, 16, 20, 24, 32, 48, 64, 128, 256, 512];

const sizes = [8, 16, 32, 64, 128, 256, 512, 768, 1024, 1536];

const radii = [4, 8, 16];

const breakpoints = ['40em', '56em', '64em'];

const boxShadow = {
  verySmall: '0 1px 2px rgba(0,0,0,0.16), 0 2px 4px rgba(0,0,0,0.23)',
  small: '0px 4px 6px rgba(0, 0, 0, 0.05), 0px 10px 15px rgba(0, 0, 0, 0.1)',
  medium: '0 4px 8px rgba(0,0,0,0.10), 0 4px 8px rgba(0,0,0,0.15)',
  bottom: '4px 0 8px rgba(0,0,0,0.10), 4px 0 8px rgba(0,0,0,0.15)',
  hovered: '0 14px 18px rgba(0,0,0,0.10), 0 10px 10px rgba(0,0,0,0.10)',
  hoveredTable: '2px 1px 2px rgba(0,0,0,0.16), 2px 2px 4px rgba(0,0,0,0.23)',
};

export const theme = {
  space,
  sizes,
  radii,
  fonts,
  fontSizes,
  fontWeights,
  borderWidths,
  lineHeights,
  letterSpacings,
  breakpoints,
  colors: {
    brand: {
      primary: '#FF4389',
      secondary: '#060707',
      accent: 'rgb(77, 144, 254)',
      muted: '#C6DAF7',
      quaternary: '#FFFFFF',
    },
    ui: {
      primary: '#FF4389',
      secondary: '#060707',
      tertiary: '#F1F1F1',
      quaternary: '#FFFFFF',
      disabled: '#DEDEDE',
      error: '#DC2222',
      success: '#3BA150',
    },
    bg: {
      primary: '#F9F6F7',
      secondary: '#FFFFFF',
      green: '#E7F4E2',
      yellow: '#FCF8E3',
      red: '#F9E6E6',
      warning: '#FFBE3F',
    },
    text: {
      primary: '#060707',
      disabled: '#9C9C9C',
      inverse: '#FFFFFF',
      error: '#D0421B',
      hint: '#715863',
      warning: '#813D07',
      success: '#138000',
    },
    grey: {
      5: '#f9f6f7',
      10: '#f8f8f8',
      30: '#e0e0e0',
      50: '#bebebe',
      70: '#707070',
      90: '#3b3b3b',
    },
    boxShadow,
  },
  newDS: omniTheme,
};

export type Theme = typeof theme;
