import { useEffect, useLayoutEffect, useRef, type DependencyList } from 'react';

import { tracker as trackerService } from '../../services';

type UseTrackerEffect = (
  effect: (tracker: typeof trackerService) => void,
  deps: DependencyList
) => void;

export const useTrackerEffect: UseTrackerEffect = (effect, deps) => {
  const effectRef = useRef(effect);

  useLayoutEffect(() => {
    effectRef.current = effect;
  });

  useEffect(() => {
    effectRef.current(trackerService);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
