import { type ComponentProps } from 'react';

import { Box } from '@omnipresentgroup/design-system';

export const Layout = (props: ComponentProps<typeof Box>) => {
  const { children, ...attributes } = props;

  return (
    <Box
      h="100%"
      ml="auto"
      mr="auto"
      maxW={1120}
      pl="16"
      pr="16"
      {...attributes}
    >
      {children}
    </Box>
  );
};
