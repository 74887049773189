import { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { trackEvent } from '@shared/features/event-tracking';

import { WithChildren } from '@global-types';

export const TrackFindInBrowser = ({ children }: WithChildren) => {
  const location = useLocation();
  const previewsKey = useRef<string>('');

  useEffect(() => {
    // Declare the events in the useEffect, and handle the clean-up in the return
    // https://reactjs.org/docs/hooks-effect.html#recap

    const detectKeyDown = (event: KeyboardEvent) => {
      if (
        (event.key == 'f' || event.key == 'F') &&
        ['Meta', 'Control'].includes(previewsKey.current)
      ) {
        trackEvent('Browser Find Triggered', {
          page: `${location.pathname}${location.hash}`,
        });
      }
      previewsKey.current = event.key;
    };
    // clean the previewsKey on key up
    const detectKeyUp = (event: KeyboardEvent) => {
      previewsKey.current = event.key;
    };

    document.addEventListener('keydown', detectKeyDown);
    document.addEventListener('keyup', detectKeyUp);

    return () => {
      document.removeEventListener('keydown', detectKeyDown);
      document.removeEventListener('keyup', detectKeyUp);
    };
  }, [location]);

  return <>{children}</>;
};
