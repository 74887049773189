import { QueryClient } from 'react-query';
import { IS_PROD } from '@shared/constants';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount) => {
        if (!IS_PROD) {
          return false;
        }

        return failureCount <= 3;
      },
      refetchOnWindowFocus: IS_PROD,
    },
  },
});
