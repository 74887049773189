import { convertToRem } from '../../utils';

export const spacing = {
  4: convertToRem(4),
  8: convertToRem(8),
  12: convertToRem(12),
  16: convertToRem(16),
  20: convertToRem(20),
  24: convertToRem(24),
  28: convertToRem(28),
  32: convertToRem(32),
  36: convertToRem(36),
  40: convertToRem(40),
  44: convertToRem(44),
  48: convertToRem(48),
  toRem: convertToRem,
} as const;
