export const colors = {
  brand: {
    primary: {
      50: '#ffecf3',
      100: '#ffc2d8',
      200: '#ff97be',
      300: '#ff6da3',
      400: '#ff4389',
      500: '#e53c7b',
      600: '#ba3164',
      700: '#991244',
    },
    white: '#ffffff',
    black: '#060707',
  },
  gray: {
    50: '#F9F6F7',
    100: '#EDE3E8',
    200: '#DCCBD1',
    300: '#BDA3AA',
    400: '#A18791',
    500: '#896B7A',
    600: '#715863',
    700: '#57454C',
    800: '#432E35',
    900: '#281A1F',
  },
  red: {
    primary: '#dd3d4d',
    dark: '#9D030D',
    light: '#f9e6e6',
  },
  green: {
    primary: '#0faf1f',
    dark: '#005C10',
    light: '#e7f4e2',
  },
  orange: {
    primary: '#ffbe3f',
    dark: '#813D07',
    light: '#fcf8e3',
  },
  blue: {
    primary: '#0066DD',
    dark: '#1D40AE',
    light: '#DAEBFE',
  },
} as const;
