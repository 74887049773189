import { Stack, Typography, Button } from '@omnipresentgroup/design-system';
import { Page, Layout } from '@shared/components';

const resetApp = () => {
  window.location.href = window.location.origin;
};

const AUTH0_ERROR_REGEXES = [/(access).*(not allowed)/i, /invalid state/i];
export const ErrorPage = (props: {
  error: any;
  resetError: any;
  componentStack: any;
}) => {
  const { error, resetError, componentStack } = props;

  const isAuth0Error = AUTH0_ERROR_REGEXES.some((regex) =>
    regex.test(error?.message)
  );

  const errorCallback = isAuth0Error ? resetError : resetApp;

  return (
    <>
      <Page title="Error">
        <Layout>
          <Stack minH="68vh" align="center">
            <Typography size="24" as="h1" weight="bold" hideParagraphSpacing>
              Ooops! You weren&apos;t supposed to see this
            </Typography>
            <Typography as="span" size="16" hideParagraphSpacing>
              {`The following error was thrown while navigating the Atlas: `}
              <Typography
                as="span"
                size="16"
                weight="bold"
                hideParagraphSpacing
              >
                {error.message}
              </Typography>
            </Typography>

            <Button onClick={errorCallback} variant="tertiary">
              Go Back
            </Button>

            <Stack mt="8">
              <Typography as="span" size="16" hideParagraphSpacing>
                {`Please provide the following information to the engineering team on the `}
                <Typography
                  as="span"
                  size="16"
                  hideParagraphSpacing
                  weight="bold"
                >
                  #knowledge-platform-team
                </Typography>
                {` slack channel:`}
              </Typography>

              <Typography as="span" size="14" hideParagraphSpacing>
                {`Error: ${error.message} `}
                {componentStack}
              </Typography>
            </Stack>
          </Stack>
        </Layout>
      </Page>
    </>
  );
};
