import axios from 'axios';

export const knowledgeAPIConfig = {
  apiKey: import.meta.env.VITE_KNOWLEDGE_API_KEY,
};

const KNOWLEDGE_API_BASE_URL =
  import.meta.env.VITE_KNOWLEDGE_API_BASE_URL || '';

const createCustomException = (ex: any) => {
  if (ex?.response?.data?.message) {
    return new Error(ex.response.data.message);
  }
  return ex;
};
export const knowledgeClient = axios.create({
  baseURL: `${KNOWLEDGE_API_BASE_URL}`,
});

knowledgeClient.interceptors.response.use(
  (response) => response,
  (error) => {
    throw createCustomException(error);
  }
);
